import './index';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Calculator from './pages/Calculator';
import History from './pages/History';

function App() {
  return (
    <>
    <Container>
      <Router>
        <Routes>
          <Route path="/" element={<History />} />
          <Route path="/calculator" element={<Calculator />} />
        </Routes>
      </Router>
      </Container>
    </>
  );
}

export default App;
