import { faArrowLeft, faArrowUpShortWide, faCalculator, faCalendarAlt, faDollar, faHome, faLayerGroup, faPercent, faSackDollar, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Row, Col, Form, Button, Table } from 'react-bootstrap';

const Calculator = () => {
  const [capital, setCapital] = useState('');
  const [percentage, setPercentage] = useState('');
  const [period, setPeriod] = useState('');
  const [depth, setDepth] = useState('');
  const [resultData, setResultData] = useState([]);
  const [calculationDone, setCalculationDone] = useState(false);
  const [totalProfit, setTotalProfit] = useState(0);

  const calculateInvestment = () => {
    const capitalNum = parseFloat(capital);
    const percentageNum = parseFloat(percentage);
    const periodNum = parseInt(period, 10);
    const depthNum = parseInt(depth, 10);

    if (capitalNum < 0.01 || capitalNum > 9999999999) {
      alert("Capital must be between 0.01 and 9,999,999,999.");
      return;
    }

    if (percentageNum < 0.01 || percentageNum > 999999) {
      alert("Percentage must be between 0.01 and 999,999.");
      return;
    }

    if (periodNum < 1 || periodNum > 365) {
      alert("Period must be between 1 and 365.");
      return;
    }

    if (depthNum < 1 || depthNum > 180) {
      alert("Depth must be between 1 and 180.");
      return;
    }

    if (!isNaN(capitalNum) && !isNaN(percentageNum) && !isNaN(periodNum) && !isNaN(depthNum)) {
      const results = [];
      let currentDate = new Date();

      for (let i = 0; i < depthNum; i++) {
        const returnValue = capitalNum * (percentageNum / 100); // Always based on initial capital
        const finalValue = capitalNum + returnValue * (i + 1);  // Incremental sum based on initial capital

        results.push({
          date: currentDate.toLocaleDateString(),
          returnValue: returnValue.toFixed(2),
          finalValue: finalValue.toFixed(2),
        });

        currentDate.setDate(currentDate.getDate() + periodNum);
      }

      setTotalProfit(results.reduce((acc, cur) => acc += Number(cur.returnValue), 0).toFixed(2));
      setResultData(results);
      setCalculationDone(true);
    } else {
      alert('Please fill in all fields correctly.');
    }
  };

  const handleBack = () => {
    setCalculationDone(false);
  };

  const clearFields = () => {
    const clean = window.confirm("Clear all fields?");
    if (!clean) return;

    setCapital('');
    setPercentage('');
    setPeriod('');
    setDepth('');
  };

  return (
    <>
      <Row className="justify-content-center">
        {calculationDone && (
          <div className="result-screen">
            <h6 className="text-center my-4">Calculation result</h6 >

            <p className='m-0'><FontAwesomeIcon icon={faSackDollar} /> Capital: <strong className='text-primary'>{capital} USDT</strong></p>
            <p className='m-0'><FontAwesomeIcon icon={faPercent} /> Percentage: <strong className='text-warning'>{percentage}% every {period} days</strong></p>
            <p className='m-0'><FontAwesomeIcon icon={faLayerGroup} /> Total results (depth): <strong className='text-danger'>{depth}</strong></p>
            <p className='m-0'><FontAwesomeIcon icon={faArrowUpShortWide} /> Total profit: <strong className='text-success'>+{totalProfit} USDT</strong></p>

            <br />
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th><FontAwesomeIcon icon={faCalendarAlt} /> Date</th>
                  <th><FontAwesomeIcon icon={faArrowUpShortWide} /> Return value</th>
                  <th><FontAwesomeIcon icon={faDollar} /> Final value</th>
                </tr>
              </thead>
              <tbody>
                {resultData.map((entry, index) => (
                  <tr key={index}>
                    <td>{entry.date}</td>
                    <td>{entry.returnValue} USDT</td>
                    <td>{entry.finalValue} USDT</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Button variant="danger" className="w-100" onClick={handleBack}>
              <FontAwesomeIcon icon={faArrowLeft} /> Go back
            </Button>
          </div>
        )}

        {!calculationDone && (
          <Col md={6}>
            <h6 className='d-flex justify-content-between'>
                Investment calculator
                <Button variant='outline-warning' onClick={() => window.location.href = '/'}>
                    <FontAwesomeIcon icon={faHome} />
                </Button>
            </h6>
            <Form id="investmentForm">
              <Form.Group className="mb-3" controlId="capital">
                <Form.Label><FontAwesomeIcon icon={faSackDollar} /> Capital (USDT)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter the capital"
                  value={capital}
                  onChange={(e) => setCapital(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="percentage">
                <Form.Label><FontAwesomeIcon icon={faPercent} /> Return percentage (%)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter the return percentage"
                  value={percentage}
                  onChange={(e) => setPercentage(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="period">
                <Form.Label><FontAwesomeIcon icon={faCalendarAlt} /> Period (days)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter the period"
                  value={period}
                  onChange={(e) => setPeriod(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="depth">
                <Form.Label><FontAwesomeIcon icon={faLayerGroup} /> Depth of calculation</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter the depth"
                  value={depth}
                  onChange={(e) => setDepth(e.target.value)}
                  required
                />
              </Form.Group>
              <Button variant="warning" className="w-100" onClick={calculateInvestment}>
                <FontAwesomeIcon icon={faCalculator} /> Calculate
              </Button>
              <Button variant="secondary" className="mt-2 w-100" onClick={clearFields}>
                <FontAwesomeIcon icon={faXmark} /> Clear fields
              </Button>
            </Form>
          </Col>
        )}
      </Row >
    </>
  );
};

export default Calculator;