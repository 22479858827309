import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

// Register Chart.js components and plugins
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const UserGainChart = ({ data }) => {
  // State to manage chart dimensions
  const [chartWidth, setChartWidth] = useState(window.innerWidth);

  // Adjust chart width on window resize
  useEffect(() => {
    const handleResize = () => setChartWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Calculate chart dimensions based on screen width
  const chartHeight = 300; // Static height
  const dynamicWidth = chartWidth > 1024 ? 600 : chartWidth * 0.9; // Smaller width on desktop

  // Define an array of colors for the bars
  const colors = [
    "rgba(75, 192, 192, 0.6)", // Teal
    "rgba(255, 99, 132, 0.6)", // Red
    "rgba(255, 206, 86, 0.6)", // Yellow
    "rgba(54, 162, 235, 0.6)", // Blue
    "rgba(153, 102, 255, 0.6)", // Purple
  ];

  const borderColors = [
    "rgba(75, 192, 192, 1)", // Teal
    "rgba(255, 99, 132, 1)", // Red
    "rgba(255, 206, 86, 1)", // Yellow
    "rgba(54, 162, 235, 1)", // Blue
    "rgba(153, 102, 255, 1)", // Purple
  ];

  // Prepare data grouped by username
  const groupedData = data.reduce((acc, curr) => {
    if (acc[curr.userName]) {
      acc[curr.userName] += curr.gain;
    } else {
      acc[curr.userName] = curr.gain;
    }
    return acc;
  }, {});

  const usernames = Object.keys(groupedData);
  const gains = Object.values(groupedData);

  // Assign colors based on the user index
  const backgroundColors = usernames.map((_, index) => colors[index % colors.length]);
  const borderColorsMapped = usernames.map((_, index) => borderColors[index % borderColors.length]);

  // Bar chart configuration
  const chartData = {
    labels: usernames,
    datasets: [
      {
        label: "Gains",
        data: gains,
        backgroundColor: backgroundColors,
        borderColor: borderColorsMapped,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: false, // Disabled to allow custom sizing
    plugins: {
      legend: { display: false }, // Hide legend (Gains label)
      title: { display: true, text: "User Gains" },
      datalabels: {
        anchor: "end",
        align: "top",
        color: "#000",
        formatter: (value) =>
          new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value), // Format as USD
        font: {
          weight: "bold",
          size: 12,
        },
      },
    },
  };

  return (
    <div /* style={{ width: dynamicWidth, height: chartHeight }} */>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default UserGainChart;
