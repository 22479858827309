import React from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Importa o plugin de rótulos

const UserGainByDateChart = ({ data }) => {
    const dates = Object.keys(data);
    const gains = Object.values(data).map((gain) => parseFloat(gain).toFixed(2)); // Formata os ganhos com duas casas decimais

    const chartData = {
        labels: dates,
        datasets: [
            {
                label: "Gain by Date",
                data: gains,
                backgroundColor: "rgba(10, 80, 150, 0.9)", // Cor do fundo
                borderColor: "rgba(10, 80, 150, 0.6)", // Cor da borda
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            datalabels: {
                color: "white", // Define a cor do texto dentro das barras como branco
                anchor: "center", // Posiciona o texto no centro da barra
                align: "center", // Centraliza verticalmente o texto
                formatter: (value) => `${value}`, // Formata o texto com unidade USDT
                font: {
                    // weight: "bold", // Deixa o texto em negrito
                },
            },
            legend: {
                labels: {
                    color: "rgba(255,255,255,1)", // Cor do texto da legenda
                },
                position: "top",
            },
            title: {
                display: true,
                text: "Total Gains by Date",
                color: "rgba(255,255,255,1)", // Cor do texto do título
            },
            tooltip: {
                callbacks: {
                    label: (context) => `Gain: ${Number(context.raw).toFixed(2)} USDT`, // Formata com duas casas decimais no tooltip
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: "rgba(255,255,255,1)", // Cor dos rótulos do eixo X
                },
            },
            y: {
                ticks: {
                    color: "rgba(255,255,255,1)", // Cor dos rótulos do eixo Y
                },
            },
        },
    };

    return <Bar data={chartData} options={options} plugins={[ChartDataLabels]} />;
};

export default UserGainByDateChart;
