import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Spinner, Table, Form, Row, Col, Card } from "react-bootstrap";
import UserGainChart from "../components/UserGainChart";
import UserGainByDateChart from "../components/UserGainByDateChart"; // Novo componente para gráfico por data

const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
};
const getFirstDayOfMonth = () => {
    const today = new Date();
    today.setDate(today.getDate() - today.getDate() + 1);
    return today.toISOString().split("T")[0];
};

let url = "http://localhost:5680/api/negotiation";

if (window.location.host.includes("danielsanchesdev")) {
    url = "https://investment-api.danielsanchesdev.com.br/api/negotiation";
}

let dolarValue = 0;

const History = () => {
    const [historyData, setHistoryData] = useState({});
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showTable, setShowTable] = useState(true);

    const [startDate, setStartDate] = useState(getFirstDayOfMonth());
    const [endDate, setEndDate] = useState(getCurrentDate());
    const [selectedUser, setSelectedUser] = useState("");

    const fetchHistoryData = async () => {
        try {
            setLoading(true);
            setError(null);

            const response = await axios.get(url, {
                params: { startDate, endDate, userName: selectedUser || null },
            });

            setHistoryData(response.data);

            if (users.length === 0) {
                const extractedUsers = [
                    ...new Set(response.data?.history?.map((item) => item.userName).filter(Boolean)),
                ];
                setUsers(extractedUsers);
            }
        } catch (err) {
            setError("Request API error");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetch("https://economia.awesomeapi.com.br/json/last/usd").then(resp => {
            resp.json().then(data => {
                dolarValue = Number(data?.USDBRL?.bid ?? 0);
            });
        });
        fetchHistoryData();
    }, []);

    const groupedByDate = selectedUser
        ? historyData.history?.reduce((acc, item) => {
            const date = new Date(item.date).toISOString().split("T")[0];
            acc[date] = (acc[date] || 0) + item.gain;
            return acc;
        }, {})
        : {};

    return (
        <>
            <h6 className="d-flex justify-content-between">
                History Data
                <Button variant="outline-warning" onClick={() => (window.location.href = "/calculator")}>
                    <FontAwesomeIcon icon={faCalculator} />
                </Button>
            </h6>

            <Form className="mb-3">
                <Row className="align-items-end">
                    <Col md={4}>
                        <Form.Group controlId="startDate">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="endDate">
                            <Form.Label>End Date</Form.Label>
                            <Form.Control
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="userSelect">
                            <Form.Label>User</Form.Label>
                            <Form.Select
                                value={selectedUser}
                                onChange={(e) => setSelectedUser(e.target.value)}
                            >
                                <option value="">All Users</option>
                                {users.map((user, index) => (
                                    <option key={index} value={user}>
                                        {user}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Button className="mt-3" variant="primary" onClick={fetchHistoryData}>
                    Filter
                </Button>
            </Form>

            {loading && (
                <div className="text-center mt-4">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Carregando...</span>
                    </Spinner>
                </div>
            )}

            {error && (
                <div className="text-center mt-4 text-danger">
                    <p>{error}</p>
                </div>
            )}

            {!loading && (
                <>
                    <Row className="d-flex justify-content-evenly mb-2">
                        <Col xs={6} md={3} className="mb-2 mb-md-0">
                            <Card className="text-success border-success">
                                <Card.Body className="d-flex justify-content-between align-items-center">
                                    <small style={{ fontSize: '11px' }} >Total (entire)</small>
                                    <h6 className="text-end m-0">
                                        $ <strong>{(historyData?.totalEntire ?? 0).toFixed(2)}</strong>
                                        <small style={{ fontSize: '10px' }} className="d-block text-muted">R$ {((historyData?.totalEntire ?? 0) * dolarValue).toFixed(2).replace('.', ',')}</small>
                                    </h6>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xs={6} md={3} className="mb-2 mb-md-0">
                            <Card className="text-primary border-primary">
                                <Card.Body className="d-flex justify-content-between align-items-center">
                                    <small style={{ fontSize: '11px' }} >Period</small>
                                    <h6 className="text-end m-0">
                                        ${" "}
                                        <strong>
                                            {((historyData?.history ?? []).reduce(
                                                (acc, cur) => (acc += cur.gain),
                                                0
                                            ) ?? 0).toFixed(2)}
                                        </strong>

                                        <small style={{ fontSize: '10px' }} className="d-block text-muted">R$ {(((historyData?.history ?? []).reduce(
                                            (acc, cur) => (acc += cur.gain),
                                            0
                                        ) ?? 0) * dolarValue).toFixed(2).replace('.', ',')}</small>
                                    </h6>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            {selectedUser ? (
                                <UserGainByDateChart data={groupedByDate} />
                            ) : (
                                <UserGainChart data={historyData.history} />
                            )}
                        </Col>
                    </Row>

                    {showTable && (
                        <Row>
                            <Col>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>User</th>
                                            <th>Gain</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {historyData?.history?.map((item) => (
                                            <tr key={item.id}>
                                                <td>{new Date(item.date).toLocaleDateString()}</td>
                                                <td>{item.userName}</td>
                                                <td className="text-success">{item.gain} USDT</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </>
    );
};

export default History;
